<template>
	<v-container id="printMe">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="mx-2" :loading="loading">
	  			<v-toolbar dense dark color="primary">
	  			  El proceso a finalizado
	  			</v-toolbar>

	  			<v-card-text>
	  				<v-row>
	  				<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.codigoaut"
		  				  label="Codigo de Autorización"
		  					readonly
		  				></v-text-field>
	  				</v-col>

						<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.fecha"
		  				  label="Fecha"
		  					readonly
		  				></v-text-field>
		  			</v-col>
		  			<v-spacer></v-spacer>

		  			<v-btn
		  				class="ma-5"
		  				color="red darken-2" >
							<v-icon color="white"
					     	@click="imprimir">print
					    </v-icon>
					  </v-btn>
		  			<!-- <v-col cols="12" xm="4" sm="3">
		  				<v-text-field
		  				  v-model="cobros.hora"
		  				  label="hora"
		  					readonly
		  				></v-text-field>
		  			</v-col> -->
		  		</v-row>

					<v-row>
						<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.tarjeta"
		  				  label="Tarjeta"
		  					readonly
		  				></v-text-field>
	  				</v-col>

	  				<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.total"
		  				  label="Total"
		  					readonly
		  				></v-text-field>
	  				</v-col>

	  				<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.moneda"
		  				  label="Moneda"
		  					readonly
		  				></v-text-field>
	  				</v-col>

					</v-row>

					<v-row>
						<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.tranid"
		  				  label="Transacción Id"
		  					readonly
		  				></v-text-field>
	  				</v-col>

	  				<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.estatus"
		  				  label="Estatus"
		  					readonly
		  				></v-text-field>
	  				</v-col>

	  				<v-col cols="12" xm="4" sm="4">
		  				<v-text-field
		  				  v-model="cobros.Tipo"
		  				  label="Tipo"
		  					readonly
		  				></v-text-field>
	  				</v-col>

					</v-row>

	  			</v-card-text>

<!-- 	  			<v-card-actions>
	  			  <v-btn small color="red darken-2" >
							<v-icon color="white"
					     	@click="imprimir">print
					    </v-icon>
					  </v-btn>
	  			</v-card-actions>
 -->
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-row>
	   	<v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-2">

        <v-card :loading="loading2" class="elevation-5 ma-1 px-1" >
          <v-card-title >
            <div class="blue--text" >Fecha: {{newFecha}}</div>
            <v-spacer></v-spacer>

            <div class="red--text" >Cotización: {{docant.Numdoc}}</div>

          </v-card-title>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1"  >

          <v-card-title
            class="ma-0 py-0">Datos del Pago en     {{moneda}}
          </v-card-title>

          <v-card-text>
            <table width="100%">
              <tr>
                <th style="text-align:left;">IMPORTE: </th>
                <th style="text-align:right;">{{ docant.Importe}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">DESCUENTO: </th>
                <th style="text-align:right;">{{ docant.descuento}}</th>
              </tr>

              <tr>
                <th style="text-align:left;">SUBTOTAL: </th>
                <th style="text-align:right;">{{ subtotal}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">IVA: </th>
                <th style="text-align:right;">{{ docant.impuesto1}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">TOTAL: </th>
                <th style="text-align:right;">{{ total}}</th>
              </tr>
            </table>
          </v-card-text>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1" >

         <!--  <v-card-title
            v-if="Recogeentienda"
            class="ma-0 py-0">Recoge en Tienda
          </v-card-title>

          <v-card-title
            v-else class="ma-0 py-0">Dirección de Envío
          </v-card-title>

          <v-card-text
            class="pa-0 ma-0"
            v-if="mismaDireccion">
              <div>Atención: {{ Clientes.atvent}}   </div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.Colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text> -->

<!--           <v-card-text class="ma-0 py-0" v-else>
              <div>{{ Direnvio}}</div>
          </v-card-text>
 -->
        </v-card>
      </v-col>

      <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-2">

       <v-card class="elevation-10 ma-1 px-1">
          <v-card-title
            class="ma-1 py-1">Datos del Cliente
          </v-card-title>

          <v-card-text >

              <div><strong>{{ Clientes.nomcli }}</strong></div>
              <div><strong>{{ Clientes.Rfc }}</strong></div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.Colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>Atención :{{ Clientes.atvent}}  </div>
                  <div> Email :{{Clientes.email1}} </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text>
        </v-card>

       </v-col>
     </v-row>
	</v-container>
</template>

<script>
import html2canvas from 'html2canvas'
import { mapGetters, mapActions } from 'vuex'

import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data () {
    return {
      token: '',
      loading: false,
      loading2: true,
      ipg: '',
      cobros: {},
      totalpagar: '',
      docant: {},
      moneda: 'MXN',
      Clientes: '',
      Recogeentienda: false,
      mismaDireccion: false,
      subtotal: 0.00,
      total: 0.00
    }
  },
  created () {
    // Colocar Token en LocalStorage
    const idusuarioweb = localStorage.getItem('idusuarioweb')
    console.log('idusuarioweb', idusuarioweb)

    const iddocum = localStorage.getItem('iddocum')
    console.log('iddocum', iddocum)

    console.log(this.$route.params.idg)

    const idtran = this.$route.params.idg
    const urlgetCobros = 'auth/api/v1/cobrosbytranid/' + idtran

    this.loading = true

    this.$http.get(urlgetCobros).then(response => {
      console.log('urlgetCobros', response)
      this.cobros = response.data

      this.cobros.fecha = response.data.fechahora.substring(0, 10)
  		this.cobros.tarjeta = response.data.tarjeta.substring(12)
  		this.cobros.hora = response.data.fechahora.substring(11, 20)
    }).catch(error => {
      this.snackbar = true; this.color = 'error'
      this.text = 'Problemas para cargar información'
      // console.log(error)
    })

    this.loading2 = true
    // CONSULTAR ORDER
    this.$http.get('api/v1/order.info/' + iddocum).then(response => {
      this.docant = response.body
      console.log('Doumento', this.docant)
      this.Clientes = this.docant.Clientes[0]

      this.invoicesNumber = this.docant.Numdoc
      // console.log(this.docant)
      if (this.docant.divisa == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio
      }

      // if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc !=''){
      //     this.Recogeentienda= false
      // } else {
      //     this.Recogeentienda= true
      // }

      if (this.docant.descuento == undefined) {
        	this.docant.descuento = 0.00
      }

      if (this.docant.impuesto1 == undefined) {
        	this.docant.impuesto1 = 0.00
      }

      this.subtotal = this.docant.Importe - this.docant.descuento
      // this.total = this.docant.Importe - this.docant.descuento + this.docant.impuesto1

      this.total = this.subtotal + this.docant.impuesto1
       	console.log('total', this.total)

       	console.log('iva', this.docant.impuesto1)

      this.loading2 = false
    }).catch(err => { console.log(err) })

    // this.$store.Login.commit("LOGEADO", true)
  },
  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),

    newFecha (fecha) {
      return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
    }
  },

  methods: {
  	imprimir () {
    	console.log('PRINT ME')
      // Pass the element id here
      this.$htmlToPaper('printMe', null, () => {
        console.log('Printing completed or was cancelled!')
      })
    }
  }

}

</script>
